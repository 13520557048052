import React, { useEffect, useRef, useState } from "react"
import { Box, Flex } from "@rebass/grid/emotion"
import { debounce } from "lodash"

import { TextElementNova } from "../../../../../nutrafol-ui-kit/src/TextElementNova"
import { Link } from "gatsby"

import { LowerWrapBoxMobile, LinkFlex } from "./header.styled"
import LogoutButton from "../../../account/logoutbutton"
import EllipseOrange from "../../../../assets/icons/nova/ellipse-orange.svg"

const LowerWrapMobile = ({ menuItems, isCurrent, handleAccountNavClick }) => {
  const navWrapperEl = useRef(null)
  const navScrollEl = useRef(null)
  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const navScroll = navScrollEl?.current
    const navWrapper = navWrapperEl?.current

    const handleScroll = () => {
      const scrollLeft = navScroll.scrollLeft
      const scrollWidth = navScroll.scrollWidth - navScroll.clientWidth
      const currentEl = navScroll.querySelector(".current")
      const rect = currentEl.getBoundingClientRect()
      const width = rect.width
      const left = rect.left
      const progress = parseInt((scrollLeft / scrollWidth) * 100)
      const activeOnLeftSide = left > -width && left < 18
      setProgress(progress)

      navWrapper.classList.toggle(
        "fade-left",
        !activeOnLeftSide && progress > 10
      )
      navWrapper.classList.toggle("fade-right", progress < 90)

      if (progress < 0) {
        navScroll.scrollLeft = 0 // Reset to start
      } else if (progress > 100) {
        navScroll.scrollLeft = scrollWidth // Reset to max
      }
    }

    const debouncedHandleScroll = debounce(handleScroll, 250)

    navScroll.addEventListener("scroll", debouncedHandleScroll)
  }, [])

  return (
    <LowerWrapBoxMobile ref={navWrapperEl} className={`fade-left fade-right`}>
      <Flex className="nav-scroll" ref={navScrollEl}>
        {menuItems.map((item) => {
          if (item.link?.includes("logout")) {
            return (
              <Box
                width={["auto"]}
                className={`menu-item-wrap logout`}
                key={item.title}
              >
                <LogoutButton
                  isAccountLink={true}
                  isVariant={true}
                  fontClass={`account-menu-item  Body-10-Dia-Normal text-nova-transparency-black-70`}
                />
              </Box>
            )
          } else {
            return (
              <Box
                width={["auto"]}
                key={item.link}
                className={`menu-item-wrap ${item.title?.toLowerCase()}`}
              >
                <Link
                  to={item.link}
                  onClick={() => handleAccountNavClick(item.link, item.title)}
                  className={`menu-item-link`}
                >
                  <LinkFlex className={``}>
                    {isCurrent(item.link) ? (
                      <Box>
                        <EllipseOrange />
                      </Box>
                    ) : null}
                    <Box>
                      <TextElementNova
                        text={item.title}
                        className={
                          `account-menu-item ` +
                          (isCurrent(item.link)
                            ? ` Body-10-Dia-Strong text-nova-base-black current`
                            : " Body-10-Dia-Normal text-nova-transparency-black-70")
                        }
                      />
                    </Box>
                  </LinkFlex>
                </Link>
              </Box>
            )
          }
        })}
      </Flex>
    </LowerWrapBoxMobile>
  )
}

export default LowerWrapMobile
