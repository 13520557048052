import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import styled from "@emotion/styled"
import { Box, Flex } from "@rebass/grid/emotion"

import theme from "../../../../../nutrafol-ui-kit/styles/theme"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { TextElementNova } from "../../../../../nutrafol-ui-kit/src/TextElementNova"

import { isIpad } from "../../../../utils/general"

import RightArrowSubMenu from "../../../../assets/icons/nova/arrow-right-solid-40.svg"
import RightArrowSubMenuGreen from "../../../../assets/icons/nova/arrow-right-green.svg"

const SubItemWrapper = styled(Box)`
  width: 100%;

  &:hover,
  &:active {
    background-color: ${theme.wild.color.nova.base.solid[20]};
  }
`
const SubItem = styled(Box)``

const DropdownMenu = styled.div`
  visibility: hidden;
  opacity: 0;
  position: absolute;
  background: transparent;
  width: max-content;
  background: ${theme.wild.color.nova.base.white};
  top: 64px;
  left: 0;
  z-index: 12;
  transition:
    visibility 0s,
    opacity 0.1s ease-in-out;
  border: 1px solid ${theme.wild.color.nova.transparency.black[20]};

  box-shadow:
    0px 1.75px 2.31px 0px #00000002,
    0px 8px 6.5px 0px #00000004,
    0px 20.25px 17.44px 0px #00000005,
    0px 40px 40px 0px #00000008;
`

const DropdownWrapper = styled(Flex)`
  background: ${theme.wild.color.nova.base.white};
  padding: 40px 40px;
  flex-direction: row;
  gap: 32px;
  flex-wrap: nowrap;
`

const NavItem = styled(Box)``

const NavItemFooter = styled(Box)`
  width: 100%;
  border: 1px solid ${theme.wild.color.nova.transparency.black[20]};
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top: none;
  padding: 8px 8px;


  .CTA-10-Dia-Normal {
    font-size: 12px !important;
  }
`

const removeActiveItemClass = (el, preventClick) => {
  const mouseLeftItem = hasHover(el)
  if (!!mouseLeftItem) {
    mouseLeftItem.classList.remove("hover")
  }
  if (preventClick) {
    el.preventDefault()
  }
  handleExpand(el)
}

const handleExpand = (el) => {
  if (el?.id) {
    ;[
      ...document.querySelectorAll("nav.navigation-variant [aria-expanded]"),
    ].forEach((a) => {
      const isHover = a?.parentElement?.classList?.contains("hover")
      a.ariaExpanded = isHover ? "true" : "false"
    })
  }
}

const hasHover = (e) => e.target.closest(".has-subnav.hover")

const buildCls = (subitem) => {
  return subitem.subtext ? "has-subtext" : "no-subtext"
}

const trackClicked = (text, destination) => {
  segmentEvent("Navigation Clicked", {
    module_location: "navigation",
    type: "button",
    text,
    destination,
  })
}

//force navigate for ipad https://nutrafol.atlassian.net/browse/NT2-8512
const onTouchStart = (url, state) => {
  if (isIpad()) {
    navigate(url, { state })
  }
}

const NavigationScience = ({ data }) => {
  const [hoveredItem, setHoveredItem] = useState(null)

  const item = data[1]

  return (
    <DropdownMenu className={`dropdown dropdown-science-desktop`}>
      <DropdownWrapper className="row">
        {item.children.map((subitem, idx) => (
          <NavItem
            flex={1}
            width={[200]}
            key={`nav-item-${idx}`}
            className={subitem.name}
            onTouchStart={() => onTouchStart(subitem.url)}
          >
            <Link
              to={subitem.url}
              key={`subitem-${idx}`}
              onClick={() => {
                trackClicked(subitem.name, subitem.url)
              }}
            >
              <SubItemWrapper
                onMouseEnter={() => setHoveredItem(subitem.name)}
                onMouseLeave={() => setHoveredItem(null)}
                onClick={removeActiveItemClass}
              >
                <SubItem
                  className={`${subitem.name.toLowerCase()} ${buildCls(
                    subitem,
                    hoveredItem
                  )} group ${hoveredItem ? "hovered" : ""}`}
                >
                  <Box>
                    <img src={subitem.image} alt={subitem.alt} />
                  </Box>
                  <NavItemFooter>
                    <Flex
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Box>
                        <TextElementNova
                          element="h4"
                          text={subitem.name}
                          className={"Body-10-Dia-Normal "}
                        />
                      </Box>
                      <RightArrowSubMenuGreen />
                    </Flex>
                    <Box pt={["4px"]}>
                      <TextElementNova
                        element="p"
                        text={subitem.text}
                        className="CTA-10-Dia-Normal text-nova-transparency-black-70"
                      />
                    </Box>
                  </NavItemFooter>
                </SubItem>
              </SubItemWrapper>
            </Link>
          </NavItem>
        ))}
      </DropdownWrapper>
    </DropdownMenu>
  )
}

export default NavigationScience
