import React, { useEffect, useState } from "react"
import { Flex, Box } from "@rebass/grid/emotion"
import styled from "@emotion/styled"

import theme from "../../../../../nutrafol-ui-kit/styles/theme"
import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { ButtonNova } from "../../../../../nutrafol-ui-kit/src/ButtonNova"
import { TextElementNova } from "../../../../../nutrafol-ui-kit/src/TextElementNova"
import Logo from "../headervariant/logo"
import DownNav from "../../../../assets/icons/wild/wild-caret.svg"
import ArrowUp from "../../../../assets/icons/nova/arrow-up-solid-40.svg"

const AltContentsContainer = styled(Flex)`
  display: flex;
  background-color: #fff;
  padding: 4px 4px;
  justify-content: flex-end;
  align-items: center;
  @media (min-width: 768px) {
    padding: 7px 16px;
  }
  @media (min-width: 1024px) {
    padding: 10px 24px;
    border-radius: 4px;
  }

  .logo-wrap {
    padding: 0;
  }
`
const ButtonsWrap = styled(Flex)`
  margin-left: auto;
  justify-content: flex-end;
  button.c-btn {
    width: 88px !important;
    @media (min-width: 360px) {
      width: 94px !important;
    }

    height: 44px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    padding-left: 4px !important;
    padding-right: 4px !important;

    // fix for label line-height override

    .label {
      line-height: 44px !important;
    }

    @media (min-width: 1024px) {
      width: 112px !important;
    }
  }
`
const ButtonsHolder = styled.div`
  position: relative;
  width: calc(100vw - 101px);
  max-width: 268px;
  height: 44px;
  background: ${theme.wild.color.nova.base.solid[20]};
  border-radius: 4px;
  margin: 0 5px 0 0;
  padding: 0;
  @media (min-width: 1024px) {
    margin: 0 8px 0 0;
  }
`
const ButtonsFloat = styled.div`
  position: absolute;
  cursor: pointer;
  width: 100%;
  max-width: 268px;
  height: auto;
  top: 0;
  padding: 8px;
  background: #ffffff;
  box-shadow:
    0 0 20px rgba(0, 0, 0, 0.05),
    0 24px 38px rgba(0, 0, 0, 0.03),
    0 9px 46px rgba(0, 0, 0, 0.03),
    0 11px 15px rgba(0, 0, 0, 0.03);
  border-radius: 4px;
`
const OptionWrap = styled.button`
  width: 100%;
  max-width: 268px;
  height: 44px;
  line-height: 44px;
  background: ${theme.color.white};
  border-radius: 4px;
  border: 1px #c8c2d1 solid;

  span,
  svg {
    display: inline-block;
  }

  span {
    line-height: 28px !important;
    //border: 1px red dotted;
  }

  .c-title {
    text-align: left;
    width: 100%;
    padding: 0 0 0 4px;
    @media (min-width: 360px) {
      padding: 0 0 0 16px;
    }
  }

  .c-savings {
    color: ${theme.wild.color.master.base.black};
    background: ${theme.color.yellow};
    border-radius: 4px;
    padding: 2px 2px;
    text-align: center;
    height: 30px;
    transform: translate(0, -1px);

    &.no-savings {
      visibility: hidden;
    }
  }

  .c-strike {
    color: ${theme.wild.color.master.base.alt.alt};
    text-decoration: line-through;
    width: 32px;
  }

  .plus-icon {
    width: 42px;
    padding: 0;
    transform: rotate(180deg);
  }

  &.normal {
    border: none;
    background: ${theme.wild.color.nova.base.solid[20]};
  }

  &.float {
    height: 42px;
    line-height: 42px;
    width: 100%;
    max-width: 252px;

    margin: 8px 0 0 0;

    .plus-icon {
      width: 4px;
      visibility: hidden;
      @media (min-width: 360px) {
        width: 16px;
      }
    }

    &.active {
      border: 1px #007a73 solid;
      background: ${theme.wild.color.nova.base.solid[20]};
    }

    &:hover,
    &:focus,
    &:focus-visible {
      // border: 1px #007a73 solid;
      //background: ${theme.wild.color.nova.base.solid[20]};
    }
  }
`

const Chooser = styled(Flex)`
  width: 100%;
  max-width: 252px;
  height: 48px;

  padding: 12px 0 0 0;

  span,
  svg {
    display: inline-block;
  }

  .c-choose {
    text-align: left;
  }

  .plus-icon {
    transform: translate(0, -1px);
    @media (min-width: 1024px) {
      transform: translate(0, -1px);
    }
  }
`

const FlexLine = styled(Flex)``

const ArrowBox = styled(Box)`
  display: none;
  @media (min-width: 768px) {
    display: block;
    height: 22px;
  }
`
const LogoBox = styled(Box)`
  display: none;
  @media (min-width: 1024px) {
    display: block;
    text-align: center;
    height: 22px;
  }
`

const atcText = "Add to Cart"

const HeaderAtcVariant = (props) => {
  const { handleNavLogoExitClick, state } = props
  const { atcData, activeOption, setActiveOption } = state
  const [isFloat, setIsFloat] = useState(false)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
  }
  const handleClick = () => {
    if (isFloat) {
      setIsFloat(false)
    }
  }
  useEffect(() => {
    if (typeof document !== "undefined") {
      const handleKey = function (evt) {
        evt = evt || window.event
        var isEscape =
          evt &&
          (evt.key === "Escape" || evt.key === "Esc" || evt.keyCode === 27)
        if (isEscape) {
          if (isFloat) {
            setIsFloat(false)

            const header = document.querySelector("header")
            const main = document.querySelector("main")
            const footer = document.querySelector("footer")

            header && header.setAttribute("data-mousedown", "1")
            main && main.setAttribute("data-mousedown", "1")
            footer && footer.setAttribute("data-mousedown", "1")
          }
        }
      }
      document.addEventListener("click", handleClick)
      document.addEventListener("keydown", handleKey)
      return () => {
        document.removeEventListener("click", handleClick)
        document.removeEventListener("keydown", handleKey)
      }
    }
  }, [isFloat])

  if (atcData === null) {
    return null
  }

  const options = atcData.options
  const getTitle = (option) => {
    return option?.ui_data?.title === "Single Bottle"
      ? "One-Time Purchase"
      : option?.ui_data?.title
  }

  const atcClick = () => {
    if (isSubmitted) {
      return
    }
    handleClick()
    const option = options[activeOption]
    setIsSubmitted(true)
    segmentEvent("Product Added", {
      text: atcText,
      name: atcData.title,
      sku: option.sku,
      price: option.price,
      variant: getTitle(option),
      product_gender: option.product_gender,
      billing_interval: option.billing_interval,
      core_category: atcData.coreCategory,
      product_category: option.product_category,
      purchase_type: option.purchase_type,
      shipping_interval: option.shipping_interval,
      quantity: 1,
      quiz_reco: false,
      source: "pdp",
      type: "button_stickynav",
    })
    window.location.href =
      process.env.GATSBY_MAGENTO_URL +
      `dpa/add/tocart/?skus=` +
      option.sku +
      `&purchase_source=${atcData.purchase_source || atcData.source || "pdp"}`
  }

  const onSelect = (idx) => {
    const option = options[idx]
    segmentEvent("Product Selected", {
      variant: getTitle(option),
      core_category: atcData.coreCategory,
      name: atcData.title,
      sku: option.sku,
      price: option.price,
      product_gender: option.product_gender,
      type: "dropdown_stickynav",
    })
  }

  const onOpen = () => {
    const option = options[activeOption]
    segmentEvent("Product Dropdown Viewed", {
      image_url: atcData.mainimg,
      name: atcData.title,
      product_gender: option.product_gender,
      type: "dropdown_stickynav",
    })
  }

  const renderOption = (idx, type = "normal") => {
    const option = options && options[idx] ? options[idx] : null

    if (!option) {
      return null
    }

    return (
      <OptionWrap
        key={idx}
        id={idx}
        className={`${type} ${
          activeOption === idx ? "active" : ""
        } header-atc-option-${idx}`}
        onClick={(e) => {
          if (type === "float") {
            setActiveOption(idx)
            onSelect(idx)
          } else {
            e.nativeEvent.stopImmediatePropagation()
            setIsFloat(true)
            onOpen()
          }
        }}
      >
        <FlexLine>
          <Box width={[0.6]}>
            <TextElementNova
              element="span"
              text={getTitle(option)}
              className="Body-10-Dia-Strong c-title"
            />
          </Box>
          <Flex width={[0.4]} justifyContent={`flex-end`}>
            <Box pr={["4px"]}>
              <TextElementNova
                element="span"
                aria-label={
                  option?.old_price && option?.old_price !== option.price
                    ? `Was ${option.old_price} dollars, now discounted`
                    : ``
                }
                text={
                  option?.old_price && option?.old_price !== option.price
                    ? `$${option?.old_price}`
                    : ``
                }
                className="Body-10-Dia-Normal c-strike"
              />
            </Box>
            <Box>
              <TextElementNova
                element="span"
                text={`$${option.price}`}
                className="Body-10-Dia-Strong c-price"
              />
            </Box>
            <Box>
              <DownNav className="plus-icon" />
            </Box>
          </Flex>
        </FlexLine>
      </OptionWrap>
    )
  }

  const mapper =
    options && options.map
      ? options.map((el, idx) => renderOption(idx, "float"))
      : null

  return (
    <AltContentsContainer>
      <ArrowBox width={[0, 0, 1 / 2, "calc(50% - 80px)"]}>
        <button onClick={scrollToTop}>
          <ArrowUp />
        </button>
      </ArrowBox>
      <LogoBox width={[0, 0, 0, 160]}>
        <button onClick={handleNavLogoExitClick}>
          <Logo />
        </button>
      </LogoBox>
      <ButtonsWrap width={[1, 1, 1 / 2, "calc(50% - 80px)"]}>
        <ButtonsHolder>
          {renderOption(activeOption)}
          {isFloat && (
            <ButtonsFloat>
              <Chooser>
                <Box flex={1}>
                  <TextElementNova
                    element="span"
                    text={`Choose an Option`}
                    className="Caption-10-Dia-Uppercase c-choose"
                  />
                </Box>
                <Box>
                  <DownNav className="plus-icon" />
                </Box>
              </Chooser>
              {mapper}
            </ButtonsFloat>
          )}
        </ButtonsHolder>
        <ButtonNova
          className={`c-btn ${isSubmitted ? "disabled" : ""}`}
          primary
          label={atcText}
          onClick={atcClick}
        />
      </ButtonsWrap>
    </AltContentsContainer>
  )
}

export default HeaderAtcVariant
