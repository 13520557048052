import React, { useState, useEffect, useRef, useContext } from "react"
import classNames from "classnames"
import { Flex, Box } from "@rebass/grid/emotion"
import styled from "@emotion/styled"
import { Link } from "gatsby"

import Logo from "./logo"
import HeaderIcons from "./headericons"
import Ribbon from "./ribbon"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"

import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"
import { queries } from "@nutrafol/nutrafol-ui-kit/styles/mediaqueries"
import UserContext from "../../../../context/UserContext"
import { isProductPath } from "../../../../utils/general"

import ArrowBack from "../../../../assets/icons/nova/arrow-left.svg"

const mobileFold = 768

const HeaderWrap = styled.header`
  &[data-mousedown] {
    *:focus {
      outline: none;
    }
  }
  @media (min-width: 768px) {
    height: 80px;
  }
`
const HeaderContainer = styled.div`
  padding: 12px 15px;
  @media (min-width: 768px) {
    height: 100%;
  }
  @media (min-width: 900px) {
    padding: 20px 15px;
  }
  @media (min-width: 1024px) {
    padding: 0 25px;
  }
`
const ContentsContainer = styled.div`
  &.left-wrap,
  &.right-wrap {
    flex: 1;
  }
  @media (min-width: ${mobileFold}px) {
    font-size: 0;
    height: 100%;
    .left-wrap {
      width: calc(50% - 75px);
      align-self: center;
    }
    .center-wrap {
      width: 150px;
      align-self: center;
    }
    .right-wrap {
      width: calc(50% - 75px);
      align-self: center;
    }
  }
`
const SimpleFlex = styled(Flex)`
  @media (min-width: 768px) {
    align-items: stretch;
    height: 100%;
  }
`
const ReverseArrowLink = styled(Link)`
  span {
    display: inline-block;
    vertical-align: middle;
    color: ${theme.wild.color.master.base.black};
    border-bottom: 1px solid ${theme.wild.color.master.base.black};
    margin-top: 5px;
    ${queries.medium`
      font-size: 14px !important;
      margin-left: 8px;
      font-size: 14px !important;
      line-height: 18px !important;
    `}
    ${queries.large`
      font-size: 16px !important;
      line-height: 20px !important;
    `}
  }
`

const BackButton = styled(Flex)`
  align-items: center;
  width: fit-content;
  border-bottom: solid 1px ${theme.wild.color.master.secondary.default};
  span {
    border: none;
  }
  svg {
    path {
      fill: ${theme.wild.color.master.secondary.default};
    }
  }
`

const HeaderSimple = (props) => {
  const isMouseInHeader = useRef(false)
  const userState = useContext(UserContext)
  const [yVal, setYVal] = useState(0)

  const origin = props?.location?.state?.origin

  const pathname = props.location && props.location.pathname
  let isProductPage = false
  let isAccountPage = false
  let showCart = true
  let isFreeGiftSelectionPage = false

  if (pathname) {
    showCart = pathname.indexOf("/checkout") === -1
    isProductPage = isProductPath(pathname)
    isAccountPage = pathname.indexOf("account") > -1
    isFreeGiftSelectionPage = pathname.indexOf("/selection") > -1
  }

  useEffect(() => {
    const bodyElem = document.querySelectorAll("body")[0]

    const handleScroll = () => {
      if (!isMouseInHeader.current) {
        if (document.documentElement.scrollTop <= 85) {
          if (bodyElem.classList.contains("pagescrolled")) {
            bodyElem.classList.remove("pagescrolled")
          }
        } else if (yVal > document.documentElement.scrollTop) {
          if (bodyElem.classList.contains("pagescrolled")) {
            bodyElem.classList.remove("pagescrolled")
          }
        } else {
          if (!bodyElem.classList.contains("pagescrolled")) {
            bodyElem.classList.add("pagescrolled")
          }
        }
        setYVal(document.documentElement.scrollTop)
      }
    }

    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [yVal, setYVal])

  return (
    <HeaderWrap
      data-mousedown
      className={classNames("header-wrap header-simple", {
        mousedown: props.mouseDown,
      })}
      onMouseEnter={() => (isMouseInHeader.current = true)}
      onMouseLeave={() => (isMouseInHeader.current = false)}
      onTouchEnd={() =>
        setTimeout(() => {
          isMouseInHeader.current = false
        })
      }
    >
      <HeaderContainer className="container">
        <ContentsContainer className="wrap">
          <SimpleFlex flexWrap="wrap">
            <Box
              width={["calc(50% - 20px)", null, 3 / 10]}
              className="left-wrap"
            >
              {!userState.site ? (
                isFreeGiftSelectionPage ? (
                  <ReverseArrowLink
                    to={origin ? origin : pathname.replace("/selection/", "")}
                    onClick={() => segmentEvent("Marketing CTA Clicked", {})}
                  >
                    <BackButton className="pr-2">
                      <ArrowBack />
                      <TextElement
                        element="span"
                        text="Back"
                        className="body--regular pb-[2px] hide-md-up"
                      />
                      <TextElement
                        element="span"
                        text="Back"
                        className="body--large pb-[2px] show-md-up"
                      />
                    </BackButton>
                  </ReverseArrowLink>
                ) : (
                  <ReverseArrowLink
                    to={
                      !!userState.site
                        ? `/${userState.site}/products/`
                        : "/products/"
                    }
                    onClick={() => segmentEvent("Marketing CTA Clicked", {})}
                  >
                    {/* <LeftArrow /> */}
                    <TextElement
                      element="span"
                      text="Continue Shopping"
                      className="body--regular underlined"
                    />
                  </ReverseArrowLink>
                )
              ) : null}
            </Box>
            <Box width={[40, null, 4 / 10]} className="center-wrap">
              <Logo isSimple={true} site={userState.site} />
            </Box>
            <Box
              width={["calc(50% - 20px)", null, 3 / 10]}
              className="right-wrap"
            >
              <Flex
                flexWrap="wrap"
                alignItems="center"
                justifyContent="flex-end"
                style={{ marginTop: "1px" }}
              >
                <HeaderIcons
                  isSimple
                  isProductPage={isProductPage}
                  isAccountPage={isAccountPage}
                  showCart={showCart}
                />
              </Flex>
            </Box>
          </SimpleFlex>
        </ContentsContainer>
        {props.withRibbon && <Ribbon />}
      </HeaderContainer>
    </HeaderWrap>
  )
}

export default HeaderSimple
