import styled from "@emotion/styled"
import { Box, Flex } from "@rebass/grid/emotion"
import theme from "../../../../../nutrafol-ui-kit/styles/theme"

const tabletFold = 1024

export const Nav = styled.nav`
  display: none;

  .master-nav-ul {
    #products {
      display: none;
    }

    @media (min-width: 1280px) {
      padding-left: 8px;
    }
  }

  @media (min-width: ${tabletFold}px) {
    display: inline-block;
    position: static;
    width: 100%;
    height: auto;
    padding: 0;
    //margin-left: 24px;
    .navigation-logo {
      display: none;
    }
  }
  //for debugging

  [aria-expanded="true"] {
    //border: 1px orange solid;
  }

  [aria-expanded="false"] {
    //border: 1px green solid;
  }
`

export const NavUl = styled.ul`
  li:first-of-type {
    position: initial;
  }
`

export const ListItem = styled.li`
  box-sizing: content-box;

  .link-text {
    line-height: 22px !important;
  }

  //prevents menu jump on hover

  &.shop {
    min-width: 64px;
  }

  &.science {
    min-width: 60px;
  }

  &.results {
    min-width: 65px;
  }

  &:focus,
  &:hover {
    .link-text {
      //font-size: 1.08em;
      letter-spacing: -0.45px;

      &.science {
        letter-spacing: -0.35px;
      }

      &.results {
        letter-spacing: -0.45px;
      }
      &.shed {
        letter-spacing: -0.3px;
      }

      line-height: 22px !important;
      font-family: "Dinamo Bold", Helvetica, Arial, sans-serif !important;
    }
  }

  position: relative;
  display: inline-block;

  vertical-align: top;
  color: #041c1b;
  padding: 20px 8px;
  transition: padding 0.3s ease; /* Smooth transition */
  @media (min-width: 1100px) {
    padding: 20px 12px;
  }
  @media (min-width: 1280px) {
    padding: 20px 20px;
  }

  > a {
    border-bottom: 1px solid transparent;
  }

  .minus {
    display: none;
  }

  .plus {
    display: inline-block;
  }

  &:focus,
  &:hover {
    > a {
      color: #041c1b;
    }

    > a[href="#"] {
      cursor: default;
    }
  }

  &.has-subnav {
    &:focus,
    &.hover {
      .dropdown {
        visibility: visible;
        opacity: 1;
      }

      .minus {
        display: inline-block;
      }

      .plus {
        display: none;
      }
    }
  }

  &:focus,
  &.hover {
    > a {
      //border-bottom: 1px solid rgba(27, 27, 27, 0.4);
    }
  }

  a {
    position: relative;
    color: #041c1b;
  }
`
export const InternalNavLink = styled.a`
  display: inline-block;
`
export const ArrowSection = styled(Box)`
  display: inline-block;
  width: 20px;

  svg {
    path {
      stroke: #07253a;
    }

    width: 16px;
    height: 16px;
    transform: translate(0, 2px);
    //@media (max-width: 1279px) {
    //  width: 16px;
    //  height: 16px;
    //}
  }
`

export const NavSwitcherFlex = styled(Flex)`
  .flex {
    margin: 0 auto;
    align-items: center;
    justify-content: center;
  }

  //cursor: pointer;

  border-top: 1px ${theme.wild.color.nova.transparency.black[20]} solid;
  border-right: 1px ${theme.wild.color.nova.transparency.black[20]} solid;
  border-bottom: 1px ${theme.wild.color.nova.transparency.black[20]} solid;

  &:hover {
    background-color: ${theme.wild.color.nova.base.solid[20]};
  }

  a {
    width: 100%;
    height: 100%;
  }

  svg {
    width: 16px;
    height: 16px;
    //transform: translate(0, -1px);
  }

  &.flex-quiz {
    background-color: ${theme.wild.color.nova.brand.primary[80]};
    border-top: none;
    border-right: none;
    border-bottom: none;

    svg path {
      fill: #fff;
    }
  }
`