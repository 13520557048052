import React, { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import { graphql, useStaticQuery } from "gatsby"
import { ButtonWild } from "@nutrafol/nutrafol-ui-kit/dist/ButtonWild"

import { SearchTitle, ProductDescriptionCard } from "./search.styles"
import SearchResultsLayout from "./searchresultslayout"
import ProductList from "./productlist"
import LinkedItems from "./linkitems"
import LoadingSpinner from "../loading/loadingspinner"

import { segmentEvent } from "@nutrafol/nutrafol-ui-kit/utils/segmentfunctions"
import { TextElement } from "@nutrafol/nutrafol-ui-kit/dist/TextElement"

import { SearchContext } from "./searchcontext"

import { setCookie } from "../../utils/cookies"

export const contentfulProductsQuery = graphql`
  {
    allContentfulProduct {
      ... on ContentfulProductConnection {
        nodes {
          title
          urlPath
          subTitle
          shortDescription {
            shortDescription
          }
          longDescription {
            longDescription
          }
          searchImage {
            url
          }
          relatedProducts {
            title
            subTitle
            urlPath
            searchImage {
              url
            }
          }
        }
      }
    }
  }
`

/**
 *
 * @param {{}} allContentfulProduct
 * @returns {{}}
 */
const transformContentfulData = ({ allContentfulProduct }) => {
  const allImages = {}
  allContentfulProduct?.nodes.map((item) => {
    const imageUrl = item?.searchImage?.url
    const imageId = imageUrl?.split("/")[4]
    Object.assign(allImages, {
      [imageId]: {
        image: imageUrl,
        title: item.title,
        slug: item.urlPath,
        subTitle: item.subTitle,
        shortDescription: item.shortDescription.shortDescription,
        description: item.longDescription.longDescription,
        relatedProducts: item.relatedProducts,
      },
    })
  })
  return allImages
}

/**
 *
 * @param {{}} data
 * @param {[]} faq
 * @param {Function} onClick
 * @returns {JSX.Element}
 * @constructor
 */
const ProductSearch = ({ data, onClick, queryID }) => {
  const { searchState, zendeskSearchIndex } = useContext(SearchContext)
  const [relatedFAQsLoading, setRelatedFAQsLoading] = useState(true)
  const [relatedFAQs, setRelatedFAQs] = useState([])

  const results = useStaticQuery(contentfulProductsQuery)
  const allProducts = transformContentfulData(results)
  const product = allProducts[data?.imageId] || {}
  const relatedProducts = product?.relatedProducts || []

  useEffect(() => {
    const fetchRelatedFAQs = async () => {
      if (zendeskSearchIndex) {
        try {
          const relatedFAQs = await zendeskSearchIndex.search(
            data?.shortDescription,
            {
              hitsPerPage: 5,
            }
          )
          setRelatedFAQs(relatedFAQs?.hits)
        } catch (error) {
          console.error(`Algolia API error related articles ${error}`)
        } finally {
          setRelatedFAQsLoading(false)
        }
      }
    }
    fetchRelatedFAQs()
  }, [data, data?.objectID, data?.title, zendeskSearchIndex])

  if (!data || relatedFAQsLoading) {
    return (
      <div className="relative w-full h-full">
        <LoadingSpinner />
      </div>
    )
  }

  return (
    <SearchResultsLayout>
      <div className={"flex flex-col product-container"}>
        <SearchTitle text={"Recommended"} />
        <ProductDescriptionCard
          className={
            "flex gap-4 sm:gap-6 mt-[-4px] sm:mt-0 items-center flex-wrap"
          }
          onClick={() => {
            segmentEvent("Search Clicked", {
              destination: product.slug,
              name: "Search",
              location: "navigation",
              state: searchState,
              text: product.title,
              type: "Link",
              // Algolia Properties
              index: "Products",
              eventType: "click",
              queryID,
              objectID: product?.objectID,
            })
            setCookie("queryID", queryID)
            navigate(product.slug)
          }}
        >
          <div className="img-container w-[50%] sm:w-[188px]">
            <img
              src={product.image}
              className={"rounded-lg object-cover"}
              alt={product.title}
            />
          </div>
          <div className="description-container flex-1 text-left">
            <TextElement
              text={product.title}
              className={"body--xlarge-b product-title mb-1"}
            />
            <TextElement
              text={product.shortDescription}
              className={"show-md-up body--regular mb-1"}
            />
            <ButtonWild label={"Shop Now"} arrowRight textLink />
          </div>
          <div className="mobile-description hide-md-up w-100 mt-[-8px]">
            <TextElement
              text={product.shortDescription}
              className={"body--regular mb-1 text-left"}
            />
          </div>
        </ProductDescriptionCard>
        {Boolean(relatedProducts?.length) ? (
          <div className={"below-recommended"}>
            <div className={`mb-4 w-[calc(100%+16px)] sm:w-[100%]`}>
              <TextElement
                text={"You might also be interested in..."}
                className="body--large-b mt-8 mb-1 sm:mb-2"
              />
              <div className={"recommended-container mb-4 sm:mb-10"}>
                <ProductList products={relatedProducts.slice(0, 4)} />
              </div>
            </div>
          </div>
        ) : null}
      </div>
      <div>
        <LinkedItems title={"Related"} items={relatedFAQs} onClick={onClick} />
      </div>
    </SearchResultsLayout>
  )
}

export default ProductSearch
